<template>
  <div class="columns is-multiline">
    <div class="column is-6">
      <r-card outlined class="column is-12">
        <div class="column is-12">
          <h3 class="board-title">Professional Information</h3>
        </div>
        <div class="column is-12">
          <ValidationProvider
            name="position"
            v-slot="{ errors }"
            rules="required"
          >
            <b-field
              label="Position"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
            >
              <b-select
                v-if="positions && positions.length > 0"
                v-model="form.position_id"
                :expanded="true"
                placeholder="Select a position"
              >
                <optgroup
                  v-for="option in positions"
                  :key="'employment-' + option.id"
                  :label="option.departmentName"
                >
                  <option
                    v-for="position in option.positions"
                    :key="position.id"
                    :value="position.id"
                  >
                    {{ position.positionName }}
                  </option>
                </optgroup>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-12">
          <ValidationProvider
            name="join date"
            v-slot="{ errors }"
            rules="required"
          >
            <b-field
              label="Join date"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
            >
              <b-datepicker
                placeholder="Select a date"
                v-model="form.join_date"
              ></b-datepicker>
            </b-field>
          </ValidationProvider>
        </div>
      </r-card>
      <r-card outlined class="column is-12">
        <div class="column is-12">
          <h3 class="board-title">Employment Information</h3>
        </div>
        <div class="column is-12">
          <ValidationProvider
            name="employment id"
            v-slot="{ errors }"
            rules="required|alpha_num"
          >
            <b-field
              label="Employment ID"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
            >
              <b-input v-model="form.nik"></b-input>
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-12">
          <ValidationProvider
            name="contract type"
            v-slot="{ errors }"
            rules="required"
          >
            <b-field
              label="Contract Type"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
            >
              <b-select
                placeholder="Select a contract type"
                v-model="form.contract_type"
                :expanded="true"
              >
                <option v-for="(option, index) in work_status" :key="index">
                  {{ option }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>

        <div class="column is-12">
          <ValidationProvider
            name="annual leave"
            v-slot="{ errors }"
            rules="required|numeric|max_value:12"
          >
            <b-field
              :label="annualLeaveType"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
            >
              <b-input v-model="form.annual_leave"></b-input>
            </b-field>
          </ValidationProvider>
        </div>
        <div
          class="columns is-multiline"
          v-if="form.contract_type != 'PWKTT (karyawan tetap)'"
        >
          <div class="column is-5 ml-3">
            <ValidationProvider
              name="contract start"
              v-slot="{ errors }"
              :rules="
                form.contract_type != 'PWKTT (karyawan tetap)' ? 'required' : ''
              "
            >
              <b-field
                label="Contract Start"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
                v-if="form.contract_type != 'PWKTT (karyawan tetap)'"
              >
                <b-datepicker v-model="form.start_contract_at"></b-datepicker>
              </b-field>
            </ValidationProvider>
          </div>
          <div
            class="column is-1 mt-5 mr-4"
            v-if="form.contract_type != 'PWKTT (karyawan tetap)'"
          >
            Until
          </div>
          <div class="column is-5 ml-3">
            <ValidationProvider
              name="contract end"
              v-slot="{ errors }"
              :rules="
                form.contract_type != 'PWKTT (karyawan tetap)' ? 'required' : ''
              "
            >
              <b-field
                label="Contract End"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
                v-if="form.contract_type != 'PWKTT (karyawan tetap)'"
              >
                <b-datepicker v-model="form.end_contract_at"></b-datepicker>
              </b-field>
            </ValidationProvider>
          </div>
        </div>
        <div class="column is-12">
          <b-switch
            v-model="data.has_probation"
            :left-label="true"
            :value="true"
            type="is-primary"
            class="label-bold"
          >
            Is Probation Active
          </b-switch>
        </div>
        <div class="columns is-multiline" v-if="data.has_probation">
          <div class="column is-5 ml-3">
            <ValidationProvider
              name="probation start date"
              v-slot="{ errors }"
              rules="required"
            >
              <b-field
                label="Probation Start"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-datepicker
                  placeholder="Select a date"
                  v-model="form.probation_start_at"
                ></b-datepicker>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-1 mt-5 mr-4">Until</div>
          <div class="column is-5 ml-3">
            <ValidationProvider
              name="probation end at"
              v-slot="{ errors }"
              rules="required"
            >
              <b-field
                label="Probation Date"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-datepicker
                  placeholder="Select a date"
                  v-model="form.probation_end_at"
                ></b-datepicker>
              </b-field>
            </ValidationProvider>
          </div>
        </div>
        <div class="column is-12">
          <ValidationProvider
            name="location"
            v-slot="{ errors }"
            rules="required"
          >
            <b-field
              label="Location"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
            >
              <b-select v-model="form.location_id" :expanded="true">
                <option
                  v-for="option in locations"
                  :value="option.id"
                  :key="'employment-' + option.id"
                >
                  {{ option.locationName }}
                  {{ textSubString(option.address, 80, '...') }},
                  {{ option.city }} {{ option.province }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-12">
          <b-field label="Is Active Employee">
            <b-checkbox
              v-model="form.is_active"
              true-value="1"
              false-value="0"
              :checked="1"
            ></b-checkbox>
          </b-field>
        </div>
        <div class="column is-12">
          <b-field label="Is Working Remotely" class="optional">
            <b-checkbox
              v-model="form.is_remote"
              true-value="1"
              false-value="0"
            ></b-checkbox>
          </b-field>
        </div>
      </r-card>
    </div>
    <div class="column is-6">
      <r-card outlined class="column is-12">
        <div class="column is-12">
          <h3 class="board-title">Basic Salary</h3>
        </div>
        <div class="column is-12">
          <ValidationProvider
            name="salary"
            rules="required|numeric"
            v-slot="{ errors }"
          >
            <b-field
              label="Salary"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
            >
              <currency-input
                v-model="form.base_salary"
                :allow-negative="false"
                :precision="{ min: 0, max: 0 }"
                :distraction-free="false"
              ></currency-input>
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-12">
          <b-field>
            <b-switch
              v-model="isSwitchedCustom"
              :left-label="true"
              :value="true"
              type="is-primary"
              class="label-bold"
            >
              Probation Salary
            </b-switch>
            <currency-input
              v-if="isSwitchedCustom"
              v-model="form.probation_salary"
              class="is-12"
              :allow-negative="false"
              :precision="{ min: 0, max: 0 }"
              :distraction-free="false"
            ></currency-input>
          </b-field>
        </div>
        <div class="column is-12 mt-0">
          <hr class="black-divider" />
        </div>
        <div class="column is-12">
          <h3 class="board-title">Allowances</h3>
        </div>
        <div class="column is-12">
          <ValidationProvider
            name="food"
            rules="required|numeric"
            v-slot="{ errors }"
          >
            <b-field
              label="Food"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
            >
              <currency-input
                v-model="form.eat_allowance"
                placeholder="e.g 500000"
                :allow-negative="false"
                :precision="{ min: 0, max: 0 }"
                :distraction-free="false"
              ></currency-input>
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-12">
          <ValidationProvider
            name="transport"
            rules="required|numeric"
            v-slot="{ errors }"
          >
            <b-field
              label="Transport"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
            >
              <currency-input
                v-model="form.transport_allowance"
                placeholder="e.g 500000"
                type="number"
                :allow-negative="false"
                :precision="{ min: 0, max: 0 }"
                :distraction-free="false"
              ></currency-input>
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-12">
          <ValidationProvider
            name="position"
            rules="required|numeric"
            v-slot="{ errors }"
          >
            <b-field
              label="Position"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
            >
              <currency-input
                v-model="form.positional_allowance"
                placeholder="e.g 500000"
                :allow-negative="false"
                :precision="{ min: 0, max: 0 }"
                :distraction-free="false"
              ></currency-input>
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-12">
          <b-field label="Additional Allowances"></b-field>
        </div>
        <div
          :key="index"
          class="columns is-multiline"
          v-for="(inputID, index) in form.employment_allowances"
        >
          <div class="column is-5 ml-3 mr-5 pr-0">
            <b-select
              placeholder="Select Type"
              v-if="allowanceTypeList.length >= 0"
              :value="form.employment_allowances[index].allowanceSetting.id"
              @input="changeAllowanceId($event, index)"
            >
              <option
                v-for="option in allowanceTypeList"
                :value="option.id"
                :key="option.id"
              >
                {{ option.name }}
              </option>
            </b-select>
          </div>
          <div class="column is-5 pl-0 pr-0">
            <currency-input
              v-model="form.employment_allowances[index].allowanceAmount"
              placeholder="e.g 500000"
              :allow-negative="false"
              :precision="{ min: 0, max: 0 }"
              :distraction-free="false"
            ></currency-input>
          </div>
          <div class="column is-1 mt-2">
            <b-icon
              icon="delete"
              class="click"
              @click.native="deleteInput(index)"
            ></b-icon>
          </div>
        </div>
        <div class="column is-12 mr-3 pt-0">
          <b-button
            class="is-command has-red-text"
            icon-left="plus"
            @click="addInput"
          >
            New Allowances
          </b-button>
        </div>
      </r-card>
      <r-card outlined class="columr-cardn is-12">
        <div class="column is-12">
          <h3 class="board-title">Reporting to</h3>
        </div>
        <div class="column is-12">
          <div class="columns is-multiline is-mobile">
            <div class="column is-10">
              <b-autocomplete
                v-model="reportingToFirstName"
                open-on-focus
                icon-right="chevron-down"
                :data="employees"
                placeholder="e.g. Choose Employee.."
                field="firstName"
                @select="reportingToSelected"
                @focus="getApiEmployees([])"
                @typing="getApiEmployees"
                @infinite-scroll="addPageReportingTo"
                :check-infinite-scroll="true"
              >
                <template slot-scope="props">
                  {{ props.option.firstName }}
                  {{ props.option.middleName }}
                  {{ props.option.lastName }}
                </template>
              </b-autocomplete>
              <div
                class="columns is-multiline mt-2"
                v-if="selectedReportedToInfo"
              >
                <div class="column is-1">
                  <div class="reporting-to-profile-picture-container">
                    <img
                      :src="determineProfilePicture(selectedReportedToInfo)"
                    />
                  </div>
                </div>
                <div class="column reporting-to-component">
                  <div
                    class="column is-12 reporting-to-component__name has-text-weight-semi-bold"
                  >
                    {{
                      selectedReportedToInfo.firstName +
                      ' ' +
                      selectedReportedToInfo.lastName
                    }}
                  </div>
                  <div class="column is-12 reporting-to-component__position">
                    {{
                      selectedReportedToInfo.employment
                        ? selectedReportedToInfo.employment.position
                            .positionName
                        : '-'
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-2">
              <b-icon
                icon="delete"
                class="click"
                @click.native="$emit('clear-reporting-to')"
              ></b-icon>
            </div>
          </div>
        </div>
      </r-card>
    </div>
  </div>
</template>
<script>
import CurrencyInput from '../components/CurrencyInput.vue'
import ApiService from '@/services/common/api.service'
import { mapActions } from 'vuex'

export default {
  components: { CurrencyInput },
  props: [
    'propsData',
    'propsForm',
    'employees',
    'getApiEmployees',
    'getSelectedEmployees',
    'getNextEmployees',
    'selectedReportedToInfo',
  ],
  data() {
    return {
      data: this.propsData,
      form: this.propsForm,
      perPage: 20,
      page: 0,
      lastPage: 0,
      total: 0,
      isAllowanceTypeLoading: false,
      isFromCreate: false,
      isSwitchedCustom: false,
      hasProbation: false,
      positions: [],
      work_status: [
        'PWKTT (karyawan tetap)',
        'PKWT (karyawan kontrak full time)',
        'PKPW (karyawan paruh waktu/part time)',
        'PKLW (karyawan lepas waktu/freelance)',
        'PKM (karyawan magang/intern)',
      ],
      type_of_work_status: ['part time', 'full time'],
      locations: [],
      employee_next_page_url: 1,
      reportingToFirstName: '',
      last_employee_id: null,
      annualLeaveType: 'Annual Leave Quota',
    }
  },

  created() {
    this.getApiPositions()
    this.getApiLocations()
    this.loadAllowanceSettingsList()
  },
  watch: {
    'form.reporting_to': function (val) {
      if (val?.id) {
        this.reportingToFirstName = val.firstName
      } else {
        this.reportingToFirstName = null
      }
    },
    'form.position_id': function (val) {
      for (let department of this.positions) {
        if (department.positions.length > 0) {
          let positions = department.positions
          let result = positions.find((element) => element.id === val)
          if (result) {
            if (parseInt(result.type) === 2) {
              this.annualLeaveType = 'Initial Annual Leave Quota'
            } else {
              this.annualLeaveType = 'Annual Leave Quota'
            }
            break
          }
        }
      }
    },
  },

  methods: {
    changeAllowanceId(value, index) {
      this.form.employment_allowances[index].allowanceId = value
    },
    ...mapActions({
      actionLoadAllowanceTypeList: 'allowanceModule/getAllowanceList',
    }),

    emitData() {
      this.$emit('on-input-typed', this.form)
    },

    getApiPositions() {
      ApiService.get(
        '/api/space-roketin/department?sort_field=department_name&sort_order=asc'
      ).then((response) => {
        this.positions = response.data
      })
    },
    getApiLocations() {
      ApiService.get('/api/space-roketin/location?page=1').then((response) => {
        this.locations = response.data
      })
    },
    employeeSelected(option) {
      this.form.user_id = option.id
    },

    async loadAllowanceSettingsList() {
      this.isAllowanceTypeListLoading = true
      try {
        const response = await this.actionLoadAllowanceTypeList({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
        })

        this.total = response.data.total
        this.lastPage = response.data.lastPage
        this.allowanceTypeList = response.data.data
      } catch (err) {
        console.log(err)
      }
      this.isAllowanceTypeListLoading = false
    },

    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadAllowanceTypelist()
        this.isLoadMore = false
      }
    },

    async reportingToSelected(option) {
      if (option) {
        this.form.reporting_to = option
        this.reportingToFirstName = option.firstName
        this.getSelectedEmployees()
      }
    },

    addPageReportingTo() {
      this.employee_next_page_url += 1
      this.getNextEmployees(
        '/api/space-roketin/user?page=' + this.employee_next_page_url
      )
    },

    addInput() {
      this.form.employment_allowances.push({
        allowanceSetting: { id: null },
        allowanceId: null,
        allowanceAmount: 0,
      })
    },

    deleteInput(index) {
      this.$swal({
        icon: 'warning',
        showCancelButton: true,
        text: `Are you sure want to delete this allowance field? You can't undo this action`,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
      }).then((res) => {
        if (res && res.isConfirmed) {
          this.form.employment_allowances.splice(index, 1)
        }
      })
    },

    determineProfilePicture(user) {
      if (!user.profilePictureUrl) {
        if (user.gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return user.profilePictureUrl
    },
  },
  mounted() {
    if (this.form?.reporting_to?.id) {
      this.reportingToFirstName = this.form.reporting_to.firstName
    }
  },
}
</script>
