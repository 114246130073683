<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">People</h2>
    </template>
    <template #page-content>
      <b-steps
        v-model="activeStep"
        :animated="true"
        :rounded="true"
        label-position="bottom"
        mobile-mode="minimalist"
      >
        <b-step-item
          icon="google-circles-communities"
          label="General Information"
          :clickable="isStepsClickable"
          :type="activeStep > 0 ? 'is-success' : ''"
        >
          <ValidationObserver ref="form1" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submitGeneralInformation)">
              <general-information-component
                :props-data="data"
                :emails="data.allWorkEmails"
                :all-emails="allEmails"
                :is-edit="true"
                :list-timezones="listTimezones"
                @on-input-typed="setDataStep1"
                @on-select-email="onSelectEmail"
              ></general-information-component>
            </form>
          </ValidationObserver>
        </b-step-item>
        <b-step-item
          icon="account-supervisor-circle"
          label="Professional Information"
          :clickable="isStepsClickable"
          :type="activeStep > 1 ? 'is-success' : ''"
        >
          <ValidationObserver ref="secondStep" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submitProfessionalInformation)">
              <proffesional-information-component
                :props-data="data"
                :props-form="form"
                :employees="employees"
                :get-api-employees="getApiEmployees"
                :get-selected-employees="getSelectedEmployees"
                :get-next-employees="getNextApiEmployees"
                :selected-reported-to-info="selectedReportedToInfo"
                @clear-reporting-to="clearReportingTo"
                @on-input-typed="setDataStep2"
              ></proffesional-information-component>
            </form>
          </ValidationObserver>
        </b-step-item>
        <b-step-item
          icon="file-document"
          label="Summary"
          :clickable="isStepsClickable"
        >
          <summary-information-component
            :props-data="data"
            :props-form="form"
          ></summary-information-component>
        </b-step-item>
        <template #navigation="{ previous, next }">
          <div class="level step-footer">
            <div class="level-right">
              <b-button
                type="is-disabled"
                :disabled="previous.disabled"
                @click.prevent="previous.action"
              >
                Back
              </b-button>
            </div>
            <div class="level-right">
              <b-button
                type="is-primary"
                v-if="
                  data.first_name != null &&
                  form.nik != null &&
                  activeStep === 2
                "
                @click.prevent="confirm()"
              >
                Confirm
              </b-button>
              <b-button
                type="is-primary"
                v-else
                :disabled="isDisabled"
                @click.prevent="saveAndNext(next)"
              >
                Save & Next
              </b-button>
            </div>
          </div>
        </template>
      </b-steps>
    </template>
  </r-page>
</template>
<script>
import ApiService from '@/services/common/api.service'
import moment from 'moment'
import { formatCurrency, formatDate, showToast } from '@/services/util'

import GeneralInformationComponent from '../../components/GeneralInformationComponent.vue'
import ProffesionalInformationComponent from '../../components/ProffesionalInformationComponent.vue'
import SummaryInformationComponent from '../../components/SummaryInformationComponent.vue'
import debounce from 'lodash/debounce'

export default {
  components: {
    GeneralInformationComponent,
    ProffesionalInformationComponent,
    SummaryInformationComponent,
  },
  data() {
    const today = new Date()
    return {
      data: {
        id: null,
        ktp: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        nick_name: '',
        allWorkEmails: null,
        work_email: null,
        personal_email: '',
        personal_phone: '',
        place_of_birth: '',
        date_of_birth: null,
        nationality: '',
        gender: null,
        blood_type: null,
        religion: null,
        password: 'userApp123!',
        address: '',
        rt: '',
        rw: '',
        district: '',
        village: '',
        postal_code: '',
        city: '',
        province: '',
        timezone: null,
        is_admin: 0,
        has_probation: false,
      },

      form: {
        user_id: '',
        nik: '',
        contract_type: '',
        type_of_worker: '',
        join_date: null,
        start_contract_at: null,
        probation_start_at: null,
        probation_end_at: null,
        end_contract_at: null,
        is_remote: '',
        location_id: '',
        position_id: '',
        reporting_to: {},
        is_active: 0,
        base_salary: 0,
        probation_salary: 0,
        positional_allowance: 0,
        eat_allowance: 0,
        transport_allowance: 0,
        annual_leave: 12,
        employment_allowances: [],
      },
      isDisabled: false,
      listTimezones: moment.tz.names(),
      allEmails: null,
      idEmail: null,
      responseStep1: {},
      responseStep2: {},
      isFromCreate: false,
      isSwitchedCustom: false,
      employees: [],
      positions: [],
      work_status: ['permanent', 'contract'],
      type_of_work_status: ['part time', 'full time'],
      locations: [],
      employee_next_page_url: 1,
      last_employee_id: null,
      // --- End of Form Employment ---
      activeStep: 0,
      isStepsClickable: true,
      selectedReportedToInfo: null,
      minDate: new Date(
        today.getFullYear() - 100,
        today.getMonth(),
        today.getDate()
      ),
      maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
    }
  },
  async mounted() {
    if (this.$route.query.create) {
      this.activeStep = 1
      this.isFromCreate = true
    } else {
      this.activeStep = 0
      this.isFromCreate = false
    }
    await this.getOfficeEmail()
  },

  methods: {
    formatCurrency(number) {
      return formatCurrency(number)
    },
    clearReportingTo() {
      if (this.form?.reporting_to?.id) {
        this.form.reporting_to = {}
        this.selectedReportedToInfo = null
      }
    },

    formatDate(date, format) {
      return formatDate(date, format)
    },

    setDataStep1(val) {
      this.data = { ...val }
    },

    setDataStep2(val) {
      console.log('setDataStep2')
      this.form = { ...val }
    },

    getOfficeEmail() {
      ApiService.get(
        '/api/space-roketin/employee/registration-from-spaceid?sort_field=users.id&sort_order=ASC'
      ).then((response) => {
        this.data.allWorkEmails = response.data.data.map((we) => we.workEmail)
        this.allEmails = response.data.data
      })
    },
    onSelectEmail(idEmail) {
      this.idEmail = idEmail
    },

    getSpecificUser() {
      return new Promise((resolve, reject) => {
        ApiService.get(
          `/api/space-roketin/user/detail?id=${this.$route.params.id}`
        ).then(
          (response) => {
            this.data.id = response.data.id
            this.data.ktp = response.data.ktp
            this.data.first_name = response.data.firstName
            this.data.middle_name = response.data.middleName
            this.data.last_name = response.data.lastName
            this.data.nick_name = response.data.nickName
            this.data.work_email = response.data.workEmail
            this.data.personal_email = response.data.personalEmail.toLowerCase()
            this.data.personal_phone = response.data.personalPhone
            this.data.place_of_birth = response.data.placeOfBirth
            this.data.date_of_birth = new Date(response.data.dateOfBirth)
            this.data.nationality = response.data.nationality
            this.data.blood_type = response.data.bloodType
            this.data.religion = response.data.religion
            this.data.gender = response.data.gender
            this.data.address = response.data.address.streetName
            this.data.rt = response.data.address.rt
            this.data.rw = response.data.address.rw
            this.data.district = response.data.address.district
            this.data.village = response.data.address.village
            this.data.postal_code = response.data.address.postalCode
            this.data.city = response.data.address.city
            this.data.province = response.data.address.province
            this.data.timezone = response.data.timezone
            this.data.is_admin = response.data.isAdmin
            this.data.has_probation = response.data.hasProbation
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    async getSpecificUserEmployment() {
      return new Promise((resolve, reject) => {
        ApiService.get(
          `/api/space-roketin/user/detail?id=${this.$route.params.id}`
        ).then(
          (response) => {
            if (response.data.employment) {
              this.form.id = response.data.employment.id
              this.form.user_id = response.data.employment.user_id
              this.form.nik = response.data.employment.nik
              this.form.contract_type = response.data.employment.contractType
              this.form.type_of_worker = response.data.employment.typeOfWorker
              this.form.join_date = new Date(response.data.employment.joinDate)
              this.form.start_contract_at = new Date(
                response.data.employment.startContractAt
              )
              this.form.probation_start_at = new Date(
                response.data.employment.probationStartAt
              )
              this.form.probation_end_at = new Date(
                response.data.employment.probationEndAt
              )
              this.form.end_contract_at = new Date(
                response.data.employment.endContractAt
              )
              this.form.is_remote = response.data.employment.isRemote
              this.form.location_id = response.data.employment.locationId
              this.form.position_id = response.data.employment.positionId
              this.form.reporting_to = response.data.employment.reportingTo
                ? response.data.employment.reportingTo
                : null
              this.form.is_active = response.data.employment.isActive
              this.form.base_salary = response.data.employment.baseSalary
                ? response.data.employment.baseSalary
                : 0
              this.form.probation_salary = response.data.employment
                .probationSalary
                ? response.data.employment.probationSalary
                : 0
              this.form.positional_allowance = response.data.employment
                .positionalAllowance
                ? response.data.employment.positionalAllowance
                : 0
              this.form.eat_allowance = response.data.employment.eatAllowance
                ? response.data.employment.eatAllowance
                : 0
              this.form.transport_allowance = response.data.employment
                .transportAllowance
                ? response.data.employment.transportAllowance
                : 0

              this.form.annual_leave = response.data.employment.annualLeave
              this.form.employment_allowances = response.data.employment
                .employmentAllowances
                ? response.data.employment.employmentAllowances
                : []
            } else {
              this.form.id = null
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    confirm() {
      this.$swal({
        icon: 'success',
        titleText: 'Success!',
        subtitleText: 'New people has been added',
        confirmButtonText: 'Done',
        customClass: {
          confirmButton: 'button is-success',
        },
      })
      this.$router.push('/employment/general')
    },

    saveAndNext(next) {
      this.isDisabled = true
      if (this.activeStep === 0) {
        this.$refs.form1.validate().then(async (success) => {
          if (!success) {
            console.log('ERROR!')
          } else {
            this.submitGeneralInformation().then((response) => {
              console.log(response)
              next.action()
            })
          }
        })
      } else if (this.activeStep === 1) {
        this.$refs.secondStep.validate().then(async (success) => {
          if (!success) {
            console.log(this.form)
          } else {
            this.submitProfessionalInformation().then((response) => {
              console.log(response)
              next.action()
            })
          }
        })
      } else if (this.activeStep === 2) {
        next.action()
      }
      this.isDisabled = false
    },
    submitGeneralInformation() {
      return new Promise((resolve, reject) => {
        let params = {
          id: this.data.id,
          ktp: this.data.ktp,
          firstName: this.data.first_name,
          middleName: this.data.middle_name,
          lastName: this.data.last_name,
          nickName: this.data.nick_name,
          workEmail: this.data.work_email.toLowerCase(),
          personalEmail: this.data.personal_email.toLowerCase(),
          personalPhone: this.data.personal_phone,
          placeOfBirth: this.data.place_of_birth,
          dateOfBirth: moment(this.data.date_of_birth).format('YYYY-MM-DD'),
          nationality: this.data.nationality,
          gender: this.data.gender,
          bloodType: this.data.blood_type,
          religion: this.data.religion,
          password: this.data.password,
          address: this.data.address,
          rt: this.data.rt,
          rw: this.data.rw,
          district: this.data.district,
          village: this.data.village,
          postalCode: this.data.postal_code,
          city: this.data.city,
          province: this.data.province,
          timezone: this.data.timezone,
          isAdmin: this.data.is_admin,
        }

        ApiService.put(
          `/api/space-roketin/user/update/basic/${this.$route.params.id}`,
          params
        ).then(
          (response) => {
            this.responseStep1 = response.data

            resolve(response)
            showToast('Updated Successfully', 'is-success', 'is-top')
          },
          (error) => {
            reject(error)
            showToast(error.response.meta.message, 'is-danger', 'is-top')
          }
        )
      })
    },

    getApiEmployees(search) {
      this.employees = []
      this.getNextApiEmployees('/api/space-roketin/user?page=1', search)
    },

    getNextApiEmployees: debounce(function (nextUrl, search) {
      if (nextUrl == null) {
        return
      }

      if (search) {
        search = {
          search: search,
        }
      }

      ApiService.get(nextUrl, search).then((response) => {
        if (this.employees.length != 0) {
          this.employees.splice(this.employees.length - 1, 1)
        }

        let employees = response.data.data

        employees.forEach((employee) => {
          this.employees.push(employee)
        })

        let key = employees.length - 1

        this.last_employee_id = employees[key].id

        this.employee_next_page_url = response.data.links.next
        this.form.user_id = ''

        if (response.data.links.next == null) {
          return
        }
      })
    }, 500),

    async getSelectedEmployees() {
      try {
        if (this.form && this.form.reporting_to && this.form.reporting_to.id) {
          let response = await ApiService.get(
            '/api/space-roketin/user/detail?id=' + this.form.reporting_to.id
          )
          this.selectedReportedToInfo = response.data
          console.log(this.selectedReportedToInfo)
        }
      } catch (e) {
        console.log(e)
      }
    },

    submitProfessionalInformation() {
      return new Promise((resolve, reject) => {
        let params = {
          user_id: this.$route.params.id,
          nik: this.form.nik,
          contract_type: this.form.contract_type,
          join_date: moment(this.form.join_date).format('YYYY-MM-DD'),
          start_contract_at:
            this.form.contract_type !== 'PWKTT (karyawan tetap)' &&
            this.form.start_contract_at
              ? moment(this.form.start_contract_at).format('YYYY-MM-DD')
              : null,
          end_contract_at:
            this.form.contract_type !== 'PWKTT (karyawan tetap)' &&
            this.form.end_contract_at
              ? moment(this.form.end_contract_at).format('YYYY-MM-DD')
              : null,
          has_probation: this.data.has_probation,
          probation_start_at: moment(this.form.probation_start_at).format(
            'YYYY-MM-DD'
          ),
          probation_end_at: moment(this.form.probation_end_at).format(
            'YYYY-MM-DD'
          ),
          is_remote: this.form.is_remote,
          location_id: this.form.location_id,
          position_id: this.form.position_id,
          reporting_to: this.form?.reporting_to?.id
            ? this.form.reporting_to.id
            : null,
          is_active: this.form.is_active,
          base_salary: this.form.base_salary,
          probation_salary: this.form.probation_salary,
          positional_allowance: this.form.positional_allowance,
          eat_allowance: this.form.eat_allowance,
          transport_allowance: this.form.transport_allowance,
          annual_leave: this.form.annual_leave,
          employment_allowance: this.form.employment_allowances,
        }
        if (!this.form.id) {
          if (params.has_probation == false) {
            delete params.probation_start_at
            delete params.probation_end_at
          }
          ApiService.post(`/api/space-roketin/employment`, params).then(
            (response) => {
              this.responseStep2 = response.data
              resolve(response)
              showToast('Add Success', 'is-success', 'is-top')
            },
            (error) => {
              reject(error)
              showToast(error.response.meta.message, 'is-danger', 'is-top')
            }
          )
        } else {
          if (params.has_probation == false) {
            delete params.probation_start_at
            delete params.probation_end_at
          }
          ApiService.put(
            `/api/space-roketin/employment/${this.form.id}`,
            params
          ).then(
            (response) => {
              this.responseStep2 = response.data
              resolve(response)
              showToast('Updated Successfully', 'is-success', 'is-top')
            },
            (error) => {
              reject(error)
              showToast(error.response.meta.message, 'is-danger', 'is-top')
            }
          )
        }
      })
    },
  },

  created() {
    this.getApiEmployees()
  },

  computed: {
    workEmailLowerCase() {
      return this.data.workEmail.toLowerCase()
    },
  },

  watch: {
    activeStep: {
      handler(value) {
        if (value === 0) {
          this.getSpecificUser()
        } else if (value === 1) {
          this.getSpecificUserEmployment()
        } else if (value === 2) {
          this.getSpecificUserEmployment()
        }
      },
      immediate: true,
    },

    currentUser: function () {
      if (this.currentUser != null) {
        this.selectedTimezone = this.currentUser.timezone
      }
    },
  },
}
</script>
